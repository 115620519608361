var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card is-shadowless"},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-dark has-text-weight-bold mr-3"},[_vm._v(_vm._s(_vm.disabled ? 'Detail' : 'Update Order'))]),_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1)])]),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"name":"Order ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Order ID","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":_vm.data.short_order_id,"disabled":""}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Address","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":_vm.data.address,"disabled":""}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Address Detail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Address Detail","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":_vm.data.address_detail,"disabled":""}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"City","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":_vm.data.city,"disabled":""}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Province"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Province","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":_vm.data.provinsi,"disabled":""}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Postal Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Postal Code","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":_vm.data.postal_code,"disabled":""}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Phone","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":_vm.data.phone,"disabled":""}})],1)]}}])})],1),_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"name":"Pending Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Pending Date","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datetimepicker',{attrs:{"placeholder":"Select Date","disabled":_vm.disabled,"locale":"id-ID"},model:{value:(_vm.data.pending_date),callback:function ($$v) {_vm.$set(_vm.data, "pending_date", $$v)},expression:"data.pending_date"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Process Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Process Date","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datetimepicker',{attrs:{"placeholder":"Select Date","disabled":_vm.disabled,"locale":"id-ID"},model:{value:(_vm.data.process_date),callback:function ($$v) {_vm.$set(_vm.data, "process_date", $$v)},expression:"data.process_date"}})],1)]}}])}),_vm._l((_vm.data.product_orders),function(product,key){return _c('div',{key:key,staticClass:"mb-2"},[_c('h4',{staticClass:"has-text-weight-bold mb-2"},[_vm._v(_vm._s(product.name))]),_c('div',{staticClass:"is-flex"},[_c('img',{staticClass:"product-img",attrs:{"src":product.photo,"alt":product.name}}),_c('div',{staticClass:"mx-3"},[_c('div',{staticClass:"is-flex"},[_c('ValidationProvider',{staticClass:"mr-2",attrs:{"name":((product.name) + " Price"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Product Price","disabled":_vm.disabled},nativeOn:{"keyup":function($event){return _vm.handlePrice.apply(null, arguments)}},model:{value:(product.price),callback:function ($$v) {_vm.$set(product, "price", _vm._n($$v))},expression:"product.price"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":((product.name) + " Quantity"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Qty","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('div',{staticClass:"is-flex is-align-items-flex-end"},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Product Price","disabled":_vm.disabled},nativeOn:{"keyup":function($event){return _vm.handlePrice.apply(null, arguments)}},model:{value:(product.qty),callback:function ($$v) {_vm.$set(product, "qty", _vm._n($$v))},expression:"product.qty"}}),_c('b-button',{staticClass:"ml-2",attrs:{"icon-left":"trash-can-outline","type":"is-danger"},on:{"click":function($event){return _vm.removeProduct(_vm.data.product_orders, key)}}})],1)])]}}],null,true)})],1)])])])}),_c('ValidationProvider',{attrs:{"name":"Product Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Product Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Product Price","disabled":""},nativeOn:{"keyup":function($event){return _vm.handlePrice.apply(null, arguments)}},model:{value:(_vm.data.product_price),callback:function ($$v) {_vm.$set(_vm.data, "product_price", _vm._n($$v))},expression:"data.product_price"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Shipping Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Shipping Date","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datetimepicker',{attrs:{"placeholder":"Select Date","disabled":_vm.disabled,"locale":"id-ID"},model:{value:(_vm.data.shipping_date),callback:function ($$v) {_vm.$set(_vm.data, "shipping_date", $$v)},expression:"data.shipping_date"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Shipping Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Shipping Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Shipping Price","disabled":_vm.disabled},nativeOn:{"keyup":function($event){return _vm.handlePrice.apply(null, arguments)}},model:{value:(_vm.data.shipping_price),callback:function ($$v) {_vm.$set(_vm.data, "shipping_price", _vm._n($$v))},expression:"data.shipping_price"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Completed Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Completed Date","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datetimepicker',{attrs:{"placeholder":"Select Date","disabled":_vm.disabled,"locale":"id-ID"},model:{value:(_vm.data.completed_date),callback:function ($$v) {_vm.$set(_vm.data, "completed_date", $$v)},expression:"data.completed_date"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Total Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Total Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Total Price","disabled":""},model:{value:(_vm.data.total_price),callback:function ($$v) {_vm.$set(_vm.data, "total_price", _vm._n($$v))},expression:"data.total_price"}})],1)]}}])})],2)]),(_vm.disabled)?_c('b-button',{staticClass:"has-text-weight-light mt-4",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")]):_c('b-button',{staticClass:"has-text-weight-light mt-4",attrs:{"type":"is-success","expanded":"","native-type":"submit"}},[_vm._v(" Save ")])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }