<template>
  <div>
    <!--   Supply   -->
    <card-component
      title="Supply"
      icon="finance"
      hide-content
    />

    <card-component>
      <div class="is-flex">
        <b-select v-model="type" @input="getData" class="mr-2">
          <option value="">All</option>
          <option value="month">Monthly</option>
          <option value="year">Yearly</option>
          <!--            <option :value="year" v-for="year in years" :key="year">{{ year }}</option>-->
        </b-select>

        <b-datepicker type="month"
                      placeholder="Select Month"
                      v-model="monthlyDate"
                      locale="id-ID"
                      v-if="type === 'month'"
                      @input="getData">
        </b-datepicker>

        <b-select v-model="yearlyDate" @input="getData" v-if="type === 'year'">
          <option :value="year" v-for="year in years" :key="year">{{ year }}</option>
        </b-select>
      </div>
    </card-component>

    <tiles>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="cart-outline"
        :number="total_order"
        label="Count of Total Order"
      >
        <b-loading :is-full-page="false" v-model="orders.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="hexagon-multiple-outline"
        :number="total_order_qty"
        label="Count of Total Order Qty"
      >
        <b-loading :is-full-page="false" v-model="orders.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="hexagon-slice-6"
        :number="finished_order_qty"
        label="Count of Total Finished Order Qty"
      >
        <b-loading :is-full-page="false" v-model="orders.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="hexagon-slice-3"
        :number="unfinished_order_qty"
        label="Count of Total Unfinished Order Qty"
      >
        <b-loading :is-full-page="false" v-model="orders.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="currency-usd"
        :number="total_nominal"
        prefix="Rp "
        label="Sum of Total Orders"
      >
        <b-loading :is-full-page="false" v-model="orders.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="currency-usd"
        :number="finished_nominal"
        prefix="Rp "
        label="Sum of Finished Orders"
      >
        <b-loading :is-full-page="false" v-model="orders.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="source-fork"
        :number="metrics.total_feed.value"
        suffix=" Kg"
        label="Sum of Feed Buy"
      >
        <b-loading :is-full-page="false" v-model="metrics.orders.loading"></b-loading>
      </card-widget>
      <card-widget
        class="tile is-child"
        type="is-success"
        icon="pill"
        :number="metrics.total_probiotics.value"
        suffix=" Kg"
        label="Sum of Probiotics Buy"
      >
        <b-loading :is-full-page="false" v-model="metrics.orders.loading"></b-loading>
      </card-widget>
    </tiles>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import Tiles from "@/components/Tiles";
import CardWidget from "@/components/CardWidget";
import {mapActions, mapState} from "vuex";

export default {
  name: "SupplyMetrics",
  components: {CardWidget, Tiles, CardComponent},
  data: () => {
    return {
      type: '',
      monthlyDate: new Date(),
      yearlyDate: new Date().getFullYear(),
      from: null,
      to: null,
    }
  },
  computed: {
    years() {
      let current = 2020
      let res = []
      let currentYear = new Date().getFullYear()

      do {
        res.push(current)
        current++
      } while (current <= currentYear)

      return res
    },

    ...mapState('supply', [
      'metrics',
      'orders',
    ]),

    total_order() {
      let data = this.orders.data
      if (this.from)
        data = data.filter(e => e.created_at >= this.from)
      if (this.to)
        data = data.filter(e => e.created_at <= this.to)
      return data.filter(e => e.status >= 0).length
    },

    total_order_qty() {
      let data = this.orders.data
      if (this.from)
        data = data.filter(e => e.created_at >= this.from)
      if (this.to)
        data = data.filter(e => e.created_at <= this.to)
      return data.reduce((a, b) => {
        if (b.status >= 0)
          return a + b.product_orders.reduce((c, d) => {
            return c + d.qty
          }, 0)
        return a
      }, 0)
    },

    finished_order_qty() {
      let data = this.orders.data
      if (this.from)
        data = data.filter(e => e.created_at >= this.from)
      if (this.to)
        data = data.filter(e => e.created_at <= this.to)
      return data.reduce((a, b) => {
        if (b.status === 3)
          return a + b.product_orders.reduce((c, d) => {
            return c + d.qty
          }, 0)
        return a
      }, 0)
    },

    unfinished_order_qty() {
      let data = this.orders.data
      if (this.from)
        data = data.filter(e => e.created_at >= this.from)
      if (this.to)
        data = data.filter(e => e.created_at <= this.to)
      return data.reduce((a, b) => {
        if (b.status >= 0 && b.status <= 2)
          return a + b.product_orders.reduce((c, d) => {
            return c + d.qty
          }, 0)
        return a
      }, 0)
    },

    total_nominal() {
      let data = this.orders.data
      if (this.from)
        data = data.filter(e => e.created_at >= this.from)
      if (this.to)
        data = data.filter(e => e.created_at <= this.to)
      return data.reduce((a, b) => {
        if (b.status >= 0) return a + b.total_price
        return a
      }, 0)
    },

    finished_nominal() {
      let data = this.orders.data
      if (this.from)
        data = data.filter(e => e.created_at >= this.from)
      if (this.to)
        data = data.filter(e => e.created_at <= this.to)
      return data.reduce((a, b) => {
        if (b.status === 3)
          return a + b.total_price
        return a
      }, 0)
    },
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('supply', [
      'countTotalFeed',
      'countTotalProbiotics',
    ]),

    getData() {
      this.from = null
      this.to = null

      if (this.type === 'month') {
        this.from = this.$timestamp(this.$moment(this.monthlyDate).startOf('month'))
        this.to = this.$timestamp(this.$moment(this.monthlyDate).endOf('month'))
      } else if (this.type === 'year') {
        let date = this.$moment().set({year: this.yearlyDate})
        this.from = this.$timestamp(date.startOf('year'))
        this.to = this.$timestamp(date.endOf('year'))
      }

      let payload = {}
      if (this.from) payload.from = this.from
      if (this.to) payload.to = this.to
      this.countTotalFeed(payload)
      this.countTotalProbiotics(payload)
    },
  },
}
</script>

<style scoped>

</style>
