<template>
  <div class="card is-shadowless">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-dark has-text-weight-bold mr-3">{{ disabled ? 'Detail' : 'Update Order' }}</h2>
          <div @click="$emit('close')">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form autocomplete="off" @submit.prevent="handleSubmit">
            <div class="columns">
              <div class="column is-half">
                <ValidationProvider name="Order ID" rules="required" v-slot="{ errors }">
                  <b-field label="Order ID" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="data.short_order_id" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
                  <b-field label="Address" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="data.address" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Address Detail" v-slot="{ errors }">
                  <b-field label="Address Detail" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="data.address_detail" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="City" v-slot="{ errors }">
                  <b-field label="City" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="data.city" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Province" v-slot="{ errors }">
                  <b-field label="Province" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="data.provinsi" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Postal Code" v-slot="{ errors }">
                  <b-field label="Postal Code" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="data.postal_code" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Phone" v-slot="{ errors }">
                  <b-field label="Phone" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="data.phone" disabled/>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half">
                <ValidationProvider name="Pending Date" rules="required" v-slot="{ errors }">
                  <b-field label="Pending Date" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datetimepicker placeholder="Select Date"
                                      v-model="data.pending_date"
                                      :disabled="disabled"
                                      locale="id-ID">
                    </b-datetimepicker>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Process Date" v-slot="{ errors }">
                  <b-field label="Process Date" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datetimepicker placeholder="Select Date"
                                      v-model="data.process_date"
                                      :disabled="disabled"
                                      locale="id-ID">
                    </b-datetimepicker>
                  </b-field>
                </ValidationProvider>
                <div v-for="(product,key) in data.product_orders" :key="key" class="mb-2">
                  <h4 class="has-text-weight-bold mb-2">{{ product.name }}</h4>
                  <div class="is-flex">
                    <img :src="product.photo" :alt="product.name" class="product-img">
                    <div class="mx-3">
                      <div class="is-flex">
                        <ValidationProvider :name="`${product.name} Price`" rules="required" v-slot="{ errors }"
                                            class="mr-2">
                          <b-field label="Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                            <b-input type="number" v-model.number="product.price" placeholder="Input Product Price"
                                     :disabled="disabled"
                                     @keyup.native="handlePrice"></b-input>
                          </b-field>
                        </ValidationProvider>
                        <ValidationProvider :name="`${product.name} Quantity`" rules="required" v-slot="{ errors }">
                          <b-field label="Qty" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                            <div class="is-flex is-align-items-flex-end">
                              <b-input type="number" v-model.number="product.qty" placeholder="Input Product Price"
                                       :disabled="disabled"
                                       @keyup.native="handlePrice"></b-input>
                              <b-button icon-left="trash-can-outline" type="is-danger" class="ml-2" @click="removeProduct(data.product_orders, key)"></b-button>
                            </div>
                          </b-field>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
                <ValidationProvider name="Product Price" rules="required" v-slot="{ errors }">
                  <b-field label="Product Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" v-model.number="data.product_price" placeholder="Input Product Price"
                             @keyup.native="handlePrice" disabled></b-input>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Shipping Date" v-slot="{ errors }">
                  <b-field label="Shipping Date" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datetimepicker placeholder="Select Date"
                                      v-model="data.shipping_date"
                                      :disabled="disabled"
                                      locale="id-ID">
                    </b-datetimepicker>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Shipping Price" v-slot="{ errors }">
                  <b-field label="Shipping Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" v-model.number="data.shipping_price" placeholder="Input Shipping Price"
                             :disabled="disabled"
                             @keyup.native="handlePrice"></b-input>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Completed Date" v-slot="{ errors }">
                  <b-field label="Completed Date" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datetimepicker placeholder="Select Date"
                                      v-model="data.completed_date"
                                      :disabled="disabled"
                                      locale="id-ID">
                    </b-datetimepicker>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Total Price" rules="required" v-slot="{ errors }">
                  <b-field label="Total Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" v-model.number="data.total_price" placeholder="Input Total Price"
                             disabled></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <b-button type="is-primary" expanded class="has-text-weight-light mt-4" v-if="disabled" @click="$emit('close')">
              Close
            </b-button>
            <b-button type="is-success" expanded class="has-text-weight-light mt-4" native-type="submit" v-else>
              Save
            </b-button>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: "UpdateShipment",
  props: {
    order: Object,
    disabled: Boolean,
  },
  data: () => {
    return {
      data: {}
    }
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.resetData()
      }
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    resetData() {
      this.data = {
        ...cloneDeep(this.order),
        completed_date: this.order.completed_date ? new Date(this.order.completed_date) : null,
        shipping_date: this.order.shipping_date ? new Date(this.order.shipping_date) : null,
        pending_date: this.order.pending_date ? new Date(this.order.pending_date) : null,
        process_date: this.order.process_date ? new Date(this.order.process_date) : null,
      }

      this.handlePrice()
    },

    ...mapActions('supply', [
      'updateOrder'
    ]),

    handlePrice() {
      this.data.product_price = this.data.product_orders.reduce((a, b) => {
        return a + (b.price * b.qty)
      }, 0)
      this.data.total_price = this.data.shipping_price + this.data.product_price
    },

    handleSubmit() {
      return this.updateOrder({
        order_id: this.order.order_id,
        pending_date: this.data.pending_date ? this.$timestamp(this.data.pending_date) : 0,
        process_date: this.data.process_date ? this.$timestamp(this.data.process_date) : 0,
        shipping_date: this.data.shipping_date ? this.$timestamp(this.data.shipping_date) : 0,
        completed_date: this.data.completed_date ? this.$timestamp(this.data.completed_date) : 0,
        shipping_price: parseFloat(this.data.shipping_price) || 0,
        product_price: parseFloat(this.data.product_price) || 0,
        total_price: parseFloat(this.data.total_price) || 0,
        product_orders: this.data.product_orders.map(e => {
          delete e.product_order_id
          e.price = parseFloat(e.price)
          e.qty = parseFloat(e.qty)
          return e
        }),
      }).then(() => {
        this.$emit('save')
        this.$emit('close')
      }).catch(err => {
        console.log(err)
      })
    },

    removeProduct(product_orders, key) {
      product_orders.splice(key, 1)
      this.handlePrice()
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 900px !important;
  max-width: 100%;
}
</style>
