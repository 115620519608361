import { render, staticRenderFns } from "./UpdateShipment.vue?vue&type=template&id=df7835cc&scoped=true&"
import script from "./UpdateShipment.vue?vue&type=script&lang=js&"
export * from "./UpdateShipment.vue?vue&type=script&lang=js&"
import style0 from "./UpdateShipment.vue?vue&type=style&index=0&id=df7835cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7835cc",
  null
  
)

export default component.exports