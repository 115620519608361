<template>
  <div class="supply-table">

    <!--   Supply   -->
    <card-component
      title="Supply Data"
      icon="finance"
      hide-content
    />

    <card-component>
      <div class="is-flex mb-3 is-align-items-center">
        <b-select placeholder="Show All Farm" v-model="orderType" class="mr-4">
          <option :value="-2">All Purchases</option>
          <option :value="-1">Canceled</option>
          <option :value="0">Pending</option>
          <option :value="1">Processed</option>
          <option :value="2">Shipped</option>
          <option :value="3">Received</option>
        </b-select>

        <div class="is-flex">
          Total Data: <span class="has-text-primary has-text-weight-bold ml-2">{{ order_data.length }}</span>
        </div>
      </div>
      <b-table
        :loading="orders.loading"
        :paginated="paginated"
        :per-page="perPage"
        :striped="true"
        :hoverable="true"
        default-sort="pending_date"
        default-sort-direction="desc"
        sticky-header
        :data='order_data'>

        <b-table-column label="Order ID" field="short_order_id" sortable v-slot="props" searchable>
          {{ props.row.short_order_id }}
        </b-table-column>
        <b-table-column label="Full Name" field="full_name" sortable v-slot="props" searchable>
          {{ props.row.full_name }}
        </b-table-column>
        <b-table-column label="Address" field="address" sortable v-slot="props" searchable>
          {{ props.row.address }}
        </b-table-column>
        <b-table-column label="Address Detail" field="address_detail" sortable v-slot="props" searchable>
          {{ props.row.address_detail }}
        </b-table-column>
        <b-table-column label="City" field="city" sortable v-slot="props" searchable>
          {{ props.row.city }}
        </b-table-column>
        <b-table-column label="Province" field="province" sortable v-slot="props" searchable>
          {{ props.row.province }}
        </b-table-column>
        <b-table-column label="Postal Code" field="postal_code" sortable v-slot="props" searchable>
          {{ props.row.postal_code }}
        </b-table-column>
        <b-table-column label="Product Price" field="product_price" sortable v-slot="props" searchable>
          <p class="is-flex is-justify-content-space-between"
             v-for="(item, key) in props.row.product_orders" :key="key">
            {{ item.name }}<span class="has-text-success has-text-weight-bold">{{
              $currency(item.price * item.qty)
            }}</span>
          </p>
          <div class="is-divider"></div>
          <p class="is-flex is-justify-content-space-between has-text-weight-bold">Total <span
            class="has-text-success has-text-weight-bold">{{ $currency(props.row.product_price) }}</span></p>
        </b-table-column>
        <b-table-column label="Pending Date" field="pending_date" sortable v-slot="props">
          {{ $dateFormat(props.row.pending_date) }}
          {{ $timeFormat(props.row.pending_date) }}
        </b-table-column>
        <b-table-column label="Shipping Date" field="shipping_date" sortable v-slot="props">
          <div v-if="props.row.shipping_date">
            {{ $dateFormat(props.row.shipping_date) }}
            {{ $timeFormat(props.row.shipping_date) }}
          </div>
        </b-table-column>
        <b-table-column label="Shipping Price" field="shipping_price" sortable v-slot="props">
          {{ $currency(props.row.shipping_price) }}
        </b-table-column>
        <b-table-column label="Total Price" field="total_price" sortable v-slot="props">
          {{ $currency(props.row.total_price) }}
        </b-table-column>
        <b-table-column label="Status" field="status_label" sortable v-slot="props">
          <span class="has-text-weight-bold" :class="{
            'has-text-danger': props.row.status < 1,
            'has-text-info': props.row.status >= 1 && props.row.status <= 2,
            'has-text-success': props.row.status == 3,
          }">
          {{ props.row.status_label }}
          </span>
        </b-table-column>
        <b-table-column label="Action" v-slot="props">

          <b-dropdown aria-role="list" position="is-bottom-left">
            <template #trigger>
              <b-button
                type="is-primary"
                icon-right="dots-vertical"></b-button>
            </template>

            <b-dropdown-item aria-role="listitem" @click="updateOrder(props.row, true)">Detail</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="updateOrder(props.row)">Update Order</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="purchaseOrder(props.row)">Purchase Order</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" has-link>
              <a :href="`https://supply.sgara.id/purchases/${props.row.order_id}/invoice`" target="_blank">Invoice</a>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="destroyOrder(props.row)">Delete Order</b-dropdown-item>
          </b-dropdown>

        </b-table-column>

        <section class="section" slot="empty">
          <div class="content has-text-grey has-text-centered">
            <template v-if="orders.loading">
              <p>
                <b-icon icon="dots-horizontal" size="is-large"/>
              </p>
              <p>Fetching data...</p>
            </template>
            <template v-else>
              <p>
                <b-icon icon="emoticon-sad" size="is-large"/>
              </p>
              <p>Nothing's there&hellip;</p>
            </template>
          </div>
        </section>
      </b-table>
    </card-component>

    <b-modal
      v-model="shippingModal"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal>
      <template #default="props">
        <update-shipment @save="getOrders" @close="props.close" :order="selectedOrder" :disabled="disabled"/>
      </template>
    </b-modal>
    <b-modal
      v-model="purchaseModal"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal>
      <template #default="props">
        <purchase-order @save="getOrders" @close="props.close" :order="selectedOrder" :disabled="disabled"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import UpdateShipment from "./UpdateShipment";
import PurchaseOrder from "./PurchaseOrder";
import cloneDeep from 'lodash/cloneDeep'
import CardComponent from "@/components/CardComponent";

export default {
  name: 'SupplyTable',
  components: {CardComponent, PurchaseOrder, UpdateShipment},
  data() {
    return {
      paginated: true,
      perPage: 10,
      orderType: -2,
      shippingModal: false,
      purchaseModal: false,
      selectedOrder: null,
      disabled: false,
    }
  },
  computed: {
    ...mapState('supply', [
      'orders',
    ]),

    order_data() {
      let data = this.orders.data

      if (this.orderType >= -1) data = data.filter(e => e.status === this.orderType)

      return data
    },
  },
  watch: {
    'refresh.value'(value) {
      if (value) this.getOrders()
    },
  },
  created() {
    if (this.orders.data.length === 0) {
      this.getOrders()
      this.getCompanies()
    }
  },
  methods: {
    ...mapActions('supply', [
      'deleteOrder',
      'getOrders',
    ]),

    ...mapActions('company', [
      'getCompanies',
    ]),

    updateOrder(order, disabled = false) {
      this.disabled = disabled
      this.selectedOrder = cloneDeep(order)
      this.shippingModal = true
    },
    purchaseOrder(order, disabled = false) {
      this.disabled = disabled
      this.selectedOrder = cloneDeep(order)
      this.purchaseModal = true
    },
    destroyOrder(order) {
      this.$swal
        .fire({
          title: "Delete Selected Order?",
          html: "Your data " + order.order_id + " will permanently delete from database!",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes"
        })
        .then(result => {
          if (result.value) {
            this.deleteOrder(order.order_id)
              .then(() => {
                this.getOrders()
              })
              .catch(() => {
              });
          }
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.b-table {
  ::v-deep {
    .table-wrapper.has-sticky-header {
      height: auto !important;
    }
  }
}
</style>
