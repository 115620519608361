<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <supply-metrics/>
      <supply-table/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import SupplyMetrics from "@/components/Book/SupplyMetrics";
import SupplyTable from "@/components/Supply/SupplyTable";

export default {
  name: 'SupplyOverview',
  components: {
    SupplyTable,
    SupplyMetrics,
    TitleBar
  },
  computed: {
    titleStack() {
      return ['SgaraSupply', 'Overview']
    }
  },
}
</script>
