<template>
  <div class="card is-shadowless">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-dark has-text-weight-bold mr-3">Purchase Order</h2>
          <div @click="$emit('close')">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form autocomplete="off" @submit.prevent="handleSubmit">
            <div class="columns">
              <div class="column is-half">
                <ValidationProvider name="Order ID" rules="required" v-slot="{ errors }">
                  <b-field label="Order ID" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input :value="data.short_order_id" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Commision Percentage" rules="required|numeric" v-slot="{ errors }">
                  <b-field label="Commision Percentage (%)" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" v-model.number="vendor.commission" placeholder="Input Commision Percentage" @input="handlePrice"/>
                  </b-field>
                </ValidationProvider>
                <div v-for="(product,key) in data.products" :key="key" class="mb-2">
                  <h4 class="has-text-weight-bold mb-2">{{ product.name }}</h4>
                  <div class="is-flex">
                    <img :src="product.image_url" :alt="product.name" class="product-img">
                    <div class="mx-3">
                      <div class="is-flex">
                        <ValidationProvider :name="`${product.name} Price`" rules="required" v-slot="{ errors }"
                                            class="mr-2">
                          <b-field label="Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                            <b-input type="number" v-model.number="product.price" placeholder="Input Product Price"
                                     disabled
                                     @keyup.native="handlePrice"></b-input>
                          </b-field>
                        </ValidationProvider>
                        <ValidationProvider :name="`${product.name} Quantity`" rules="required" v-slot="{ errors }">
                          <b-field label="Qty" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                            <b-input type="number" v-model.number="product.qty" placeholder="Input Product Price"
                                     disabled
                                     @keyup.native="handlePrice"></b-input>
                          </b-field>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
                <ValidationProvider name="Product Price" rules="required" v-slot="{ errors }">
                  <b-field label="Product Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" v-model.number="data.product_price" placeholder="Input Product Price"
                             @keyup.native="handlePrice" disabled></b-input>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Shipping Price" v-slot="{ errors }">
                  <b-field label="Shipping Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" v-model.number="data.shipping_price" placeholder="Input Shipping Price"
                             disabled
                             @keyup.native="handlePrice"></b-input>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Total Price" rules="required" v-slot="{ errors }">
                  <b-field label="Total Price" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" v-model.number="data.total_price" placeholder="Input Total Price"
                             disabled></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half">
                <h5 class="has-text-weight-bold mb-1">Vendor Data</h5>
                <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                  <b-field label="Name" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-autocomplete
                      v-model="search"
                      :data="searchCompanies"
                      clearable
                      open-on-focus
                      class="mb-3"
                      field="name"
                      @select="handleChangeCompany">
                      <template #empty>No results found</template>
                    </b-autocomplete>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Address" rules="required" v-slot="{ errors }">
                  <b-field label="Address" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="vendor.address_detail" placeholder="Input Address" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Province" rules="required" v-slot="{ errors }">
                  <b-field label="Province" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="vendor.province" placeholder="Input Province" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="City" rules="required" v-slot="{ errors }">
                  <b-field label="City" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="vendor.city" placeholder="Input City" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="District" rules="required" v-slot="{ errors }">
                  <b-field label="District" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="vendor.district" placeholder="Input District" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Postal Code" rules="required" v-slot="{ errors }">
                  <b-field label="Postal Code" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="vendor.postal_code" placeholder="Input Postal Code" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Phone Number" rules="required" v-slot="{ errors }">
                  <b-field label="Phone Number" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="vendor.phone" placeholder="Input Phone Number" disabled/>
                  </b-field>
                </ValidationProvider>
                <ValidationProvider name="Fax" v-slot="{ errors }">
                  <b-field label="Fax" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model="vendor.fax" placeholder="Input Fax (Opsional)" disabled/>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <b-button type="is-primary" expanded class="has-text-weight-light mt-4" v-if="disabled" @click="$emit('close')">
              Close
            </b-button>
            <b-button type="is-success" expanded class="has-text-weight-light mt-4" native-type="submit" v-else>
              Purchase
            </b-button>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: "PurchaseOrder",
  props: {
    order: Object,
    disabled: Boolean,
  },
  data: () => {
    return {
      search: '',
      data: {},
      vendor: {
        company_id: null,
        name: '',
        address_detail: '',
        province: '',
        city: '',
        district: '',
        postal_code: '',
        phone: '',
        fax: '',
        commission: 0,
      },
    }
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.resetData()
      }
    },
  },
  computed: {
    ...mapState('company', [
      'companies',
    ]),

    filteredCompanies() {
      let company_ids = this.data.product_orders.reduce((a, b) => {
        let find = a.find(e => e === b.company_id)
        if (!find) a.push(b.company_id)
        return a
      }, [])

      return this.companies.data.filter(e => company_ids.includes(e.company_id))
    },

    searchCompanies() {
      return this.filteredCompanies.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) >= 0
        )
      })
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    handleChangeCompany(company) {
      this.vendor.company_id = company.company_id
      this.vendor.name = company.name || ''
      this.vendor.address_detail = company.address || ''
      this.vendor.province = company.province || ''
      this.vendor.city = company.city || ''
      this.vendor.district = company.district || ''
      this.vendor.postal_code = company.postal_code || ''
      this.vendor.commission = company.commission_percentage || 0
      this.vendor.phone = company.phone_number || ''
    },
    resetData() {
      this.data = {
        ...cloneDeep(this.order),
        completed_date: this.order.completed_date ? new Date(this.order.completed_date) : null,
        shipping_date: this.order.shipping_date ? new Date(this.order.shipping_date) : null,
        pending_date: this.order.pending_date ? new Date(this.order.pending_date) : null,
        process_date: this.order.process_date ? new Date(this.order.process_date) : null,
      }
    },

    ...mapActions('supply', [
      'updateOrder'
    ]),

    handlePrice() {
      let commission = this.vendor.commission ? this.vendor.commission : 0
      this.data.products = cloneDeep(this.order.products).map(e => {
        e.price = e.price * (100 - commission) / 100
        return e
      })

      this.data.product_price = this.data.products.reduce((a, b) => {
        return a + (b.price * b.qty)
      }, 0)
      this.data.total_price = this.data.shipping_price + this.data.product_price
    },

    handleSubmit() {
      function encodeQueryData(data) {
        const ret = [];
        for (let d in data)
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return '?' + ret.join('&');
      }

      let order_id = this.order.order_id
      let payload = encodeQueryData(this.vendor)

      window.open(`https://supply.sgara.id/purchases/${order_id}/purchase-order${payload}`)
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 900px !important;
  max-width: 100%;
}
</style>
