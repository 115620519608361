var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card is-shadowless"},[_c('div',{staticClass:"card-content"},[_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-dark has-text-weight-bold mr-3"},[_vm._v("Purchase Order")]),_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1)])]),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half"},[_c('ValidationProvider',{attrs:{"name":"Order ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Order ID","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"value":_vm.data.short_order_id,"disabled":""}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Commision Percentage","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Commision Percentage (%)","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Commision Percentage"},on:{"input":_vm.handlePrice},model:{value:(_vm.vendor.commission),callback:function ($$v) {_vm.$set(_vm.vendor, "commission", _vm._n($$v))},expression:"vendor.commission"}})],1)]}}])}),_vm._l((_vm.data.products),function(product,key){return _c('div',{key:key,staticClass:"mb-2"},[_c('h4',{staticClass:"has-text-weight-bold mb-2"},[_vm._v(_vm._s(product.name))]),_c('div',{staticClass:"is-flex"},[_c('img',{staticClass:"product-img",attrs:{"src":product.image_url,"alt":product.name}}),_c('div',{staticClass:"mx-3"},[_c('div',{staticClass:"is-flex"},[_c('ValidationProvider',{staticClass:"mr-2",attrs:{"name":((product.name) + " Price"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Product Price","disabled":""},nativeOn:{"keyup":function($event){return _vm.handlePrice.apply(null, arguments)}},model:{value:(product.price),callback:function ($$v) {_vm.$set(product, "price", _vm._n($$v))},expression:"product.price"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":((product.name) + " Quantity"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Qty","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Product Price","disabled":""},nativeOn:{"keyup":function($event){return _vm.handlePrice.apply(null, arguments)}},model:{value:(product.qty),callback:function ($$v) {_vm.$set(product, "qty", _vm._n($$v))},expression:"product.qty"}})],1)]}}],null,true)})],1)])])])}),_c('ValidationProvider',{attrs:{"name":"Product Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Product Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Product Price","disabled":""},nativeOn:{"keyup":function($event){return _vm.handlePrice.apply(null, arguments)}},model:{value:(_vm.data.product_price),callback:function ($$v) {_vm.$set(_vm.data, "product_price", _vm._n($$v))},expression:"data.product_price"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Shipping Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Shipping Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Shipping Price","disabled":""},nativeOn:{"keyup":function($event){return _vm.handlePrice.apply(null, arguments)}},model:{value:(_vm.data.shipping_price),callback:function ($$v) {_vm.$set(_vm.data, "shipping_price", _vm._n($$v))},expression:"data.shipping_price"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Total Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Total Price","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","placeholder":"Input Total Price","disabled":""},model:{value:(_vm.data.total_price),callback:function ($$v) {_vm.$set(_vm.data, "total_price", _vm._n($$v))},expression:"data.total_price"}})],1)]}}])})],2),_c('div',{staticClass:"column is-half"},[_c('h5',{staticClass:"has-text-weight-bold mb-1"},[_vm._v("Vendor Data")]),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-autocomplete',{staticClass:"mb-3",attrs:{"data":_vm.searchCompanies,"clearable":"","open-on-focus":"","field":"name"},on:{"select":_vm.handleChangeCompany},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v("No results found")]},proxy:true}],null,true),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Address","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Input Address","disabled":""},model:{value:(_vm.vendor.address_detail),callback:function ($$v) {_vm.$set(_vm.vendor, "address_detail", $$v)},expression:"vendor.address_detail"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Province","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Input Province","disabled":""},model:{value:(_vm.vendor.province),callback:function ($$v) {_vm.$set(_vm.vendor, "province", $$v)},expression:"vendor.province"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"City","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Input City","disabled":""},model:{value:(_vm.vendor.city),callback:function ($$v) {_vm.$set(_vm.vendor, "city", $$v)},expression:"vendor.city"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"District","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"District","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Input District","disabled":""},model:{value:(_vm.vendor.district),callback:function ($$v) {_vm.$set(_vm.vendor, "district", $$v)},expression:"vendor.district"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Postal Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Postal Code","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Input Postal Code","disabled":""},model:{value:(_vm.vendor.postal_code),callback:function ($$v) {_vm.$set(_vm.vendor, "postal_code", $$v)},expression:"vendor.postal_code"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Phone Number","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Input Phone Number","disabled":""},model:{value:(_vm.vendor.phone),callback:function ($$v) {_vm.$set(_vm.vendor, "phone", $$v)},expression:"vendor.phone"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Fax","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Input Fax (Opsional)","disabled":""},model:{value:(_vm.vendor.fax),callback:function ($$v) {_vm.$set(_vm.vendor, "fax", $$v)},expression:"vendor.fax"}})],1)]}}])})],1)]),(_vm.disabled)?_c('b-button',{staticClass:"has-text-weight-light mt-4",attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")]):_c('b-button',{staticClass:"has-text-weight-light mt-4",attrs:{"type":"is-success","expanded":"","native-type":"submit"}},[_vm._v(" Purchase ")])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }